<template>
  <div>
    <div class="table-page-search-wrapper" v-if="true">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="企业">
              <a-auto-complete
                v-model="queryParam.corporation"
                :data-source="dataSource"
                placeholder="请输入"
                @select="onSelect"
                @search="onSearch"
                @change="onChange"
                allow-clear
              />
              <span>{{ tip }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator"v-if="is_add">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <!--      <div style="padding: 0px;" slot-scope="record" slot="expandedRowRender">-->
      <!--        <span>公玥： {{ record.public }}</span>-->
      <!--      </div>-->
      <a slot="public" @click.prevent="handlePublic(text)" slot-scope="text">{{ text.length > 30 ? text.substring(1, 50)+'...' : text }}</a>
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a v-if="false">删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>

    <create-config-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <show-public-key-form
      :visible="publicKeyVisible"
      :model="publicKey"
      @cancel="handlePublicKeyCancel" />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'

import {
  corporation_config_list,
  corporation_config_delete,
  customs_inventory_push, corporation_config_create
} from '@/api/customs_order'
import CreateConfigForm from '@/views/a-customs_order/modules/CreateConfigForm'
import ShowPublicKeyForm from '@/views/a-customs_order/modules/ShowPublicKeyForm'
import { corporation_list } from '@/api/corporation'

export default {
  name: 'TableList',
  components: {
    ShowPublicKeyForm,
    CreateConfigForm,
    STable
  },
  data () {
    return {
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      publicKeyVisible: false,
      publicKey: '',
      tag_value: '',
      is_add: true,
      dataSource_tag: [{ name: '已推送', id: 0 }, { name: '未推送', id: 1 }],
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      showmdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '海关名称',
          dataIndex: 'customs_name'
        },
        {
          title: '海关编码',
          dataIndex: 'customs_code'
        },
        {
          title: '海关支付收款企业账号',
          dataIndex: 'recp_account'
        },
        {
          title: '收款企业名称',
          dataIndex: 'recp_name'
        },
        {
          title: '统一社会信用代码',
          dataIndex: 'recp_code'
        },
        {
          title: '证书编号',
          dataIndex: 'cert_no'
        },
        {
          title: '企业名称',
          dataIndex: 'corporation'
        },
        {
          title: '申报海关编码',
          dataIndex: 'customs_declaration_code'
        },
        {
          title: '口岸编码',
          dataIndex: 'port_code'
        },
        {
          title: '自动提交订单',
          dataIndex: 'auto_push_order',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '自动提交运单',
          dataIndex: 'auto_push_logistics',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '自动推送导入列表',
          dataIndex: 'auto_push_import_list',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '是否使用国密证书',
          dataIndex: 'is_sm',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '公玥',
          dataIndex: 'public',
          key: 'public',
          width: 250,
          scopedSlots: { customRender: 'public' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return corporation_config_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.dataSource_tag = this.$Dictionaries.is_pushed
  },
  watch: {
    showmdl (val, old) {
      this.$emit('onEdit', val)
    }
  },
  methods: {
    handleTagSearch (value) {
      this.dataSource_tag = value
    },
    handleTagSearchChange (value) {
      this.queryParam.is_pushed = value === '0'
      this.tag_value = this.dataSource_tag[value].name
    },
    time (dates, dateStrings) {
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handlePush (record) {
      customs_inventory_push(record, record.id).then(res => {
        if (res.code === 1000) {
          this.$message.success('推送成功')
          this.$refs.table.refresh()
        }
      })
    },
    handleAdd () {
      this.visible = true
    },
    handlePublic (data) {
      this.publicKey = data
      this.publicKeyVisible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePublicKeyCancel () {
      this.publicKeyVisible = false
      this.publicKey = ''
    },
    handleWatch (record) {
      this.$router.push('/e/customs_order/' + record.id + '/list')
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleUpdate (record) {
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      corporation_config_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          corporation_config_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    onChange (value) {
      if (value === undefined) {
        this.queryParam.corporation_id = undefined
        this.queryParam.corporation = undefined
      }
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      // eslint-disable-next-line eqeqeq
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      console.log(value, this.queryParam.corporation_id)
    }
  }
}
</script>
