<template>
  <div style="width: 60%; margin: 0 auto;">
    <a-form :form="form">
      <a-row :gutter="[12,12]">
        <a-col :span="16">
          <a-form-item label="DXPID">
            <a-input
              v-decorator="['dxp_id', {rules: [{required: true, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="电商企业的海关注册登记名称">
            <a-input
              v-decorator="['customs_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:100, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="电商企业的海关注册登记编号">
            <a-input
              v-decorator="['customs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="收款企业名称">
            <a-input
              v-decorator="['recp_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:100, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="收款企业统一社会信用代码">
            <a-input
              v-decorator="['recp_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:18, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="海关支付收款企业账号">
            <a-input
              v-decorator="['recp_account', {rules: [{required: true, message: '必填项，请填写信息'}, {max:60, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="证书编号">
            <a-input
              v-decorator="['cert_no', {rules: [{required: true, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="申报海关编码">
            <a-input
              v-decorator="['customs_declaration_code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:4, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="口岸编码">
            <a-input
              v-decorator="['port_code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:4, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="corporation_id" v-show="false">
            <a-input v-decorator="['corporation_id']" />
          </a-form-item>
          <a-form-item label="企业名称">
            <a-auto-complete
              v-decorator="['corporation', {rules:[{required: true, message: '必填项，请填写信息'}]}]"
              :data-source="dataSource"
              @select="onSelect"
              @search="onSearch"
              @change="onChange"
              disabled
            />
            <div class="ant-form-explain" v-if="!!tip">  {{ tip }} </div>
          </a-form-item>
          <a-form-item label="证书">
            <a-textarea v-decorator="['public', {rules: [{required: true, message: '必填项，请填写信息'}]}]" :rows="15" />
          </a-form-item>
          <a-form-item label="私玥">
            <a-textarea v-decorator="['private', {rules: [{required: false, message: '必填项，请填写信息'}]}]" :rows="15" />
            <div style="font-size: 12px; color: gray">  {{ private_tip }} </div>
          </a-form-item>
          <a-form-item label="自动提交订单">
            <a-switch
              v-decorator="['auto_push_order', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
          <a-form-item label="自动提交运单">
            <a-switch
              v-decorator="['auto_push_logistics', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
          <a-form-item label="自动推送导入列表">
            <a-switch
              v-decorator="['auto_push_import_list', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
          <a-form-item label="回调地址">
            <a-textarea v-decorator="['callback_url', {rules: [{required: true, message: '必填项，请填写信息'}, {max:128, message: '输入超出长度限制'}]}]" :auto-size="{ minRows: 1, maxRows: 3 }" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-form-item
        v-bind="buttonCol"
        style="width: 60%; margin: 0 auto"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { corporation_config_update } from '@/api/customs_order'
import { corporation_list } from '@/api/corporation'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      private_tip: '备注：私玥内容此处不展示'
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
        corporation_config_update(values, this.record.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
          })
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['dxp_id', 'customs_name', 'customs_code', 'cert_no', 'customs_declaration_code', 'port_code', 'recp_account',
          'recp_code', 'recp_name', 'corporation_id', 'corporation', 'public', 'private', 'callback_url', 'auto_push_order',
          'auto_push_logistics', 'auto_push_import_list'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      // eslint-disable-next-line eqeqeq
      const corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      console.log(value, '我是选择的ID', corporation_id)
      this.form.setFieldsValue({
        corporation_id: corporation_id
      })
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    }
  }
}
</script>
