<template>
  <a-modal
    title="公玥"
    :width="720"
    :visible="visible"
    @cancel="() => { $emit('cancel') }"
  >
    <template slot="footer" >
      <a-button @click="() => { $emit('cancel') }">关闭</a-button>
    </template>
    <a-form :form="form" v-bind="formLayout">
      <a-row :gutter="[4,4]">
        <a-col :span="24">
          <a-textarea :value="model" :rows="15"/>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: String,
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this)
    }
  },
  created () {
    console.log(this.visible)
  },
  methods: {
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
